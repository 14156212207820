.footer-container{
    width: 100vw;
    height: 100%;
    overflow: hidden;
    // background: #1E2243;
    background: #0D0127;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
    padding: 4em 0 0 0;
    .footer-all{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        .footer-header{
            display: flex;
            flex-direction: column;
            // align-items: center;
            // text-align: center;
            color: #BBBDC7;
            img{
                width: 120px;
            }
            p{
                font-size: 16px;
                color: #BEB3D8;
                line-height: 1.5em;
            }
        }
        .footer-content{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2em;
            .footer-section{
                width: 100%;
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                gap: 4em;
                div{
                    h2{
                        color: #BEB3D8;
                    }
                    p{
                        display: flex;
                        flex-direction: row;
                        gap: 0.5em;
                        color: #BEB3D8;
                        cursor: pointer;

                        .footer-link-arrow{
                            color: #FB9758;
                        }

                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .footer-banner{
                display: flex;
                flex-direction: column;
                background: #131C41;
                padding: 2em 1em;
                border-radius: 1em;
                color: #BEB3D8;
                width: 75vw;
                margin-bottom: 2em;
                gap: 1em;

                h2{
                    margin: 0;
                    font-size: 18px;
                }

                p{
                    font-size: 16px;
                }

                .footer-banner-icons{
                    display: flex;
                    flex-direction: row;
                    gap: 1em;
                    // width: 60%;
                    // justify-content: space-between;
                    .banner-icon{
                        width: 1.5em;
                        height: 1.5em;
                        color: white;
                        background: #0D1435;
                        padding: 0.5em;
                        border-radius: 0.6em;
                        cursor: pointer;

                        &:hover{
                            color: #0D1435;
                            background: white;
                        }
                    }

                    .banner-icon-first{
                        color: #0D1435 !important;
                        background: white !important;
                    }
                }
            }
        }
    }
    .footer-legal{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid rgba(255, 255, 255, 0.192);
        p{
            color: #BBBDC7;
            font-size: 14px;
        }
    }
}

@media only screen and (min-width: 768px){
    .footer-container{
        flex-direction: column;
        justify-content: space-around;
        padding: 2em 0 0 0;
        gap: 0;
        align-items: center;
        .footer-all{
            display: flex;
            flex-direction: row;
            width: 80%;
            justify-content: space-around;
            align-items: center;
            gap: 2em;
            margin: 2em 0;
            .footer-header{
                p{
                    max-width: 20vw;
                    font-size: 18px;
                }
                img{
                    width: 150px;
                }
            }
            .footer-content{
                //width: 40%;
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: center;
                width: unset;
                gap: 5em;
                .footer-section{
                    display: flex;
                    flex-direction: row;
                    //justify-content: center;
                    gap: 5em;

                    div{
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        gap: 0.4em;
                        h2{
                            margin-top: 0;
                        }
                    }
                }
                .footer-banner{
                    max-width: 15em;
                    // align-items: center;
                    justify-content: center;
                    text-align: left;
                }
            }
        }
    }
}