// :root{
//     width: 100%;
//     height:100vh;
//     overflow-x: hidden;
// }
// body{
//     height: 100vh;
//     width: 80vw;
// }

.informative-both{
    height: 100%;
    width: 100vw;
    margin: 4em 0;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: #0D0127;
    color: white;
    .informative-container{
        display: flex;
        flex-direction: column;
        width: 100vw;
        margin: 2em 0;
        align-items: center;
        text-align: center;
        .info-additional{
            display: none;
        }
        h1{
            font-size: 34px;
            background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
        }
        .informative-cards{
            display: flex;
            flex-direction: row;
            margin: 4em 0 1em 0;
            // width: 500vw;
            // gap: 6em;
            align-items: center;
            // justify-content: right;
            justify-content:end;
            transform: translateX(40.6%);
            animation: slider 30s infinite linear;
            gap: 1.5em;
            img{
                width: 30vw;
                padding: 1em;
                border: 2px solid #3F476B;
                rotate: 315deg;
                border-radius: 20px;
            }

            .span-separator{
                height: 2px;
                width: 15vw;
                background: #3F476B;
                color:white;
            }
        }
    }
}
@media only screen and (min-width: 768px){
    .informative-both{
        .informative-container{
            .info-additional{
                display: block;
                font-size: 42px;
                font-weight: 600;
            }
            .informative-cards{
                width: 70vw;
                animation: none;
                justify-content: space-around;
                transform: none;
                
                .desktop{
                    display: none !important;
                }

                img{
                    width: 8em;
                }

                .span-separator{
                    width: 100%;
                }
            }
        }
    }
}

@keyframes slider{
    0%{
        // background: green;
    }

    100%{
        // transform: translateX(-42%);
        transform: translateX(-40.6%);
    }
}