.landing2-containter{
    margin:0;
    padding: 4em 0 0 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    gap: 2em;
    .landing2-header{
        display: flex;
        flex-direction: column;
        width: 100vw;
        align-items: center;
        justify-content: center;
        h1{
            margin:1.4em 0 1em 0;
            padding: 0;
            color: white;
            text-align: center;
            span{
                background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
        p{
            color: rgb(197, 197, 197);
            line-height: 1.8em;
            text-align: center;
            padding: 0 2em;
            span{
                font-weight: 600;
                background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
    }
    .landing2-cta{
        display: flex;
        flex-direction: column;
        width: 100vw;
        align-items: center;
        justify-content: center;
        gap: 1em;
        input{
            width: 80vw;
            font-size: large;
            padding: 0.6em 0.4em;
            background: #0c0222;
            border: 1px solid #FE107A;
            border-radius: 0.4em;
            color: white;
            transition: box-shadow 0.6s;
            &:hover{
                cursor: pointer;
                box-shadow: 0 0 20px 2px #FE107A;
                transition: box-shadow 0.6s;
            }
            &::placeholder{
                color: rgb(197, 197, 197);
            }
        }
        div{
            display: flex;
            flex-direction: column;
            gap: 1em;
        }
        button{
            width: 84vw;
            font-size: large;
            // padding: 0.6em 0;
            max-height: 2.5em;
            background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
            border: none;
            border-radius: 0.4em;
            color: white;

            display: flex;
            flex-direction: row;
            align-items: center;
            // justify-content: space-around;
            justify-content: center;
            gap: 1em;
            &:hover{
                cursor: pointer;
                box-shadow: 0 0 20px 2px #FE107A;
                transition: box-shadow 0.6s;
            }
        }
        .scan-complete{
            background:#FB9758 !important;
        }
        select{
            width: 84vw;
            font-size: large;
            padding: 0.6em 0;
            background: #0c0222;
            border: 1px solid #FE107A;
            border-radius: 0.4em;
            color: white;
            transition: box-shadow 0.6s;
        }
    }
    .landing2-footer{
        display: flex;
        flex-direction: column;
        width: 100vw;
        align-items: center;
        justify-content: center;
        margin-top: 2em;
        p{
            color: rgb(197, 197, 197);
            font-size: 16px;
            text-align: center;
            padding: 0 2em;
            line-height: 1.8em;
        }
        ul{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 90vw;
            overflow: hidden;
            // background: #1E2243;
            border-radius: 1em;
            padding: 0.6em 0;
            margin: 0;
            li{
                list-style: none;
                width:10em;
                display: none;
                &:nth-child(-n+3){
                    display: flex;
                  }
                img{
                    width:20vw; //100px
                    min-height: 20px;
                    max-height: 30px;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px){
    .mobile-only{
        display: none;
    }
    .landing2-containter{
        align-items: center;
        
        .landing2-header{
            width: 50em;
            max-width: 80vw;
            h1{
                margin: 2em 0 0.4em 0;
                font-size: 60px;
            }
            p{
                font-size: 20px;
            }
        }
        .landing2-cta{
            display: flex;
            flex-direction: column;
            width: 40em;
            max-width: 60vw;
            input{
                width: 100%;
            }
            // button{
            //     // width: 20em;
            //     // width: 40%
            // }
            // select{
            //     // width: 100%;
            //     // width: 20em;
            //     // width: 60%;
            // }
            div{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }
        }
        .landing2-footer{
            P{
                font-size: 20px;
            }
            ul{
                width: 65em;
                max-width: 80vw;
                align-items: center;
                padding: 0.8em 0;
                margin: 0 0 4em 0;
                li{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // width: 12em;
                    img{
                        width:20vw; //100px
                        min-height: 20px;
                        max-height: 35px;
                    }
                }
            }
        }
    }
}

@keyframes rotate {
    from{
        rotate: 0deg;
    }
    50%{
        scale: 1 1.5;
    }
    to{
        rotate: 360deg;
    }
}