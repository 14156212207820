.footer-container {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background: #0D0127;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1em;
  padding: 4em 0 0 0;
}
.footer-container .footer-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}
.footer-container .footer-all .footer-header {
  display: flex;
  flex-direction: column;
  color: #BBBDC7;
}
.footer-container .footer-all .footer-header img {
  width: 120px;
}
.footer-container .footer-all .footer-header p {
  font-size: 16px;
  color: #BEB3D8;
  line-height: 1.5em;
}
.footer-container .footer-all .footer-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
}
.footer-container .footer-all .footer-content .footer-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4em;
}
.footer-container .footer-all .footer-content .footer-section div h2 {
  color: #BEB3D8;
}
.footer-container .footer-all .footer-content .footer-section div p {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  color: #BEB3D8;
  cursor: pointer;
}
.footer-container .footer-all .footer-content .footer-section div p .footer-link-arrow {
  color: #FB9758;
}
.footer-container .footer-all .footer-content .footer-section div p:hover {
  text-decoration: underline;
}
.footer-container .footer-all .footer-content .footer-banner {
  display: flex;
  flex-direction: column;
  background: #131C41;
  padding: 2em 1em;
  border-radius: 1em;
  color: #BEB3D8;
  width: 75vw;
  margin-bottom: 2em;
  gap: 1em;
}
.footer-container .footer-all .footer-content .footer-banner h2 {
  margin: 0;
  font-size: 18px;
}
.footer-container .footer-all .footer-content .footer-banner p {
  font-size: 16px;
}
.footer-container .footer-all .footer-content .footer-banner .footer-banner-icons {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.footer-container .footer-all .footer-content .footer-banner .footer-banner-icons .banner-icon {
  width: 1.5em;
  height: 1.5em;
  color: white;
  background: #0D1435;
  padding: 0.5em;
  border-radius: 0.6em;
  cursor: pointer;
}
.footer-container .footer-all .footer-content .footer-banner .footer-banner-icons .banner-icon:hover {
  color: #0D1435;
  background: white;
}
.footer-container .footer-all .footer-content .footer-banner .footer-banner-icons .banner-icon-first {
  color: #0D1435 !important;
  background: white !important;
}
.footer-container .footer-legal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.192);
}
.footer-container .footer-legal p {
  color: #BBBDC7;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .footer-container {
    flex-direction: column;
    justify-content: space-around;
    padding: 2em 0 0 0;
    gap: 0;
    align-items: center;
  }
  .footer-container .footer-all {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-around;
    align-items: center;
    gap: 2em;
    margin: 2em 0;
  }
  .footer-container .footer-all .footer-header p {
    max-width: 20vw;
    font-size: 18px;
  }
  .footer-container .footer-all .footer-header img {
    width: 150px;
  }
  .footer-container .footer-all .footer-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    width: unset;
    gap: 5em;
  }
  .footer-container .footer-all .footer-content .footer-section {
    display: flex;
    flex-direction: row;
    gap: 5em;
  }
  .footer-container .footer-all .footer-content .footer-section div {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 0.4em;
  }
  .footer-container .footer-all .footer-content .footer-section div h2 {
    margin-top: 0;
  }
  .footer-container .footer-all .footer-content .footer-banner {
    max-width: 15em;
    justify-content: center;
    text-align: left;
  }
}/*# sourceMappingURL=Footer.css.map */