.informative-both {
  height: 100%;
  width: 100vw;
  margin: 4em 0;
  overflow-x: hidden;
  overflow-y: hidden;
  color: white;
}
.informative-both .informative-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 2em 0;
  align-items: center;
  text-align: center;
}
.informative-both .informative-container .info-additional {
  display: none;
}
.informative-both .informative-container h1 {
  font-size: 34px;
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}
.informative-both .informative-container .informative-cards {
  display: flex;
  flex-direction: row;
  margin: 4em 0 1em 0;
  align-items: center;
  justify-content: end;
  transform: translateX(40.6%);
  animation: slider 30s infinite linear;
  gap: 1.5em;
}
.informative-both .informative-container .informative-cards img {
  width: 30vw;
  padding: 1em;
  border: 2px solid #3F476B;
  rotate: 315deg;
  border-radius: 20px;
}
.informative-both .informative-container .informative-cards .span-separator {
  height: 2px;
  width: 15vw;
  background: #3F476B;
  color: white;
}

@media only screen and (min-width: 768px) {
  .informative-both .informative-container .info-additional {
    display: block;
    font-size: 42px;
    font-weight: 600;
  }
  .informative-both .informative-container .informative-cards {
    width: 70vw;
    animation: none;
    justify-content: space-around;
    transform: none;
  }
  .informative-both .informative-container .informative-cards .desktop {
    display: none !important;
  }
  .informative-both .informative-container .informative-cards img {
    width: 8em;
  }
  .informative-both .informative-container .informative-cards .span-separator {
    width: 100%;
  }
}
@keyframes slider {
  100% {
    transform: translateX(-40.6%);
  }
}/*# sourceMappingURL=Informative.css.map */