.toast-message {
  background: darkblue;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

.register-container {
  width: 100vw;
  height: 100vh;
  background: #0D0127;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: white;
}
.register-container .register-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 84vw;
  height: 80vh;
  margin-top: 4em;
  border-radius: 1em;
  padding: 1em;
  background: #1E2243;
  box-shadow: 0px 10px 30px 5px rgba(37, 37, 37, 0.06);
}
.register-container .register-content .register-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.register-container .register-content .register-header h1 {
  margin: 0;
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.register-container .register-content .register-header p {
  line-height: 1.4em;
  color: rgba(255, 255, 255, 0.7);
}
.register-container .register-content .register-header div {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.register-container .register-content .register-header div button {
  background: rgba(255, 255, 255, 0.171);
  border: none;
  padding: 0.8em 2em;
  border-radius: 0.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.register-container .register-content .span-separator {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
.register-container .register-content .span-separator span {
  background: rgba(255, 255, 255, 0.3);
  width: 40%;
  height: 1px;
}
.register-container .register-content .register-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.register-container .register-content .register-fields .register-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.register-container .register-content .register-fields .register-field div {
  width: 100%;
  margin: 0 0 0.2em 0;
  padding: 0;
  transform: translateX(-1em);
}
.register-container .register-content .register-fields .register-field div label {
  display: flex;
  flex-direction: row;
}
.register-container .register-content .register-fields .register-field div label img {
  width: 16px;
  height: 16px;
  margin-right: 0.4em;
}
.register-container .register-content .register-fields .register-field input {
  background: #1E2243;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  padding: 0.6em 1em;
  font-size: 16px;
  border-radius: 0.4em;
  margin: 0.2em;
  width: 100%;
  color: white;
}
.register-container .register-content .register-fields .register-policy {
  display: flex;
  flex-direction: row;
  width: 84%;
  justify-content: end;
}
.register-container .register-content .register-fields .register-policy input {
  margin-right: 0.4em;
  cursor: pointer;
}
.register-container .register-content .register-fields .register-policy p {
  font-size: 14px;
  white-space: nowrap;
}
.register-container .register-content .register-fields .register-policy p span {
  color: #FE107A;
}
.register-container .register-content .register-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.register-container .register-content .register-actions .register-button {
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  color: white;
  font-size: 16px;
  width: 84%;
  padding: 0.6em 0;
  border: none;
  border-radius: 0.4em;
  margin-top: 1em;
  cursor: pointer;
}
.register-container .register-content .register-actions .register-offer {
  font-size: 14px;
}
.register-container .register-content .register-actions .register-offer span {
  color: #FE107A;
}

@media only screen and (max-height: 700px) {
  .register-container {
    width: 100vw;
    height: 100vh;
  }
  .register-container .register-content {
    width: 84vw;
    height: 80vh;
    margin-top: 4em;
    border-radius: 1em;
    padding: 1em;
  }
  .register-container .register-content .register-header h1 {
    font-size: 26px;
  }
  .register-container .register-content .register-header p {
    font-size: 16px;
  }
  .register-container .register-content .register-header div {
    gap: 1em;
  }
  .register-container .register-content .register-header div button {
    padding: 0.8em 2em;
    border-radius: 0.4em;
  }
  .register-container .register-content .register-fields {
    gap: 0.6em;
  }
  .register-container .register-content .register-fields .register-field div {
    width: 100%;
    margin: 0 0 0.2em 0;
  }
  .register-container .register-content .register-fields .register-field div label {
    font-size: 14px;
  }
  .register-container .register-content .register-fields .register-field div label img {
    width: 14px;
    height: 14px;
    margin-right: 0.4em;
  }
  .register-container .register-content .register-fields .register-field input {
    padding: 0.6em 1em;
    font-size: 16px;
    border-radius: 0.4em;
    margin: 0.2em;
    width: 100%;
  }
  .register-container .register-content .register-fields .register-policy {
    width: 84%;
  }
  .register-container .register-content .register-fields .register-policy p {
    font-size: 14px;
  }
  .register-container .register-content .register-actions {
    width: 100%;
  }
  .register-container .register-content .register-actions .register-button {
    font-size: 16px;
    width: 84%;
    padding: 0.6em 0;
    border-radius: 0.4em;
    margin-top: 1em;
  }
  .register-container .register-content .register-actions .register-offer {
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) {
  .register-container .register-content {
    width: 34em;
    height: auto;
    max-height: 70vh;
    min-height: 60vh;
  }
  .register-container .register-content .register-header p {
    width: 80%;
  }
  .register-container .register-content .span-separator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
  .register-container .register-content .span-separator span {
    width: 30%;
  }
  .register-container .register-content .register-fields {
    width: 62%;
  }
  .register-container .register-content .register-fields .register-field {
    width: 100%;
  }
  .register-container .register-content .register-fields .register-policy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  .register-container .register-content .register-actions {
    width: 68%;
  }
  .register-container .register-content .register-actions .register-button {
    width: 100%;
  }
}/*# sourceMappingURL=Register.css.map */