.main-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    .main-content{
        margin: 8em 0 0 1em;
        display: flex;
        flex-direction: column;
        gap: 1.4em;

        .main-cards{
            display: flex;
            flex-direction: row;
            gap: 1.8em;
            .card{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background: #151537;
                padding: 1.4em 1.8em;
                gap: 1em;
                border-radius: 1em;
                user-select: none;
                img{
                    width: 34px;
                    height: 34px;
                    padding: 0.8em;
                    background: #1E2243;
                    border-radius: 100%;
                }
                div{
                    display: flex;
                    flex-direction: column;
                    p, h2{
                        margin: 0;
                    }
                    h2{
                        user-select: text;
                    }
                }
            }
        }

        .main-graphs{
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 1.4em;
            .no-data{
                position: absolute;
            }
            .line-graph{
                justify-content: space-evenly;
                .line-graph-item{
                    height: 90% !important;
                    width: 70% !important;
                    margin: 1em 1em 1em 0;
                }
                .line-graph-info{
                    display: flex;
                    flex-direction: column;
                    margin: 1em;
                    height: 100%;
                    justify-content: space-around;
                    button{
                        width: 6.8em;
                        margin-top: 0.4em;
                    }
                    h1{
                        margin: 0.8em 0 0 0;
                    }
                    p{
                        margin: 0;
                        &:hover{
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
            }
            .alt-graph{
                justify-content: space-between;
                background: none;
                .bar-graph, .pie-graph{
                    background: #151537;
                    width: 48.6%;
                    height: 100%;
                    border-radius: 1em;
                    display: flex;
                    flex-direction: column;
                    justify-content:space-around;
                    align-items: center;
                    .pie-item-container{
                        width: 12em;
                        height: 12em;
                        overflow: visible;
                    }
                    .bar-item{
                        margin: 1em 0;
                        height: 80% !important;
                    }
                    .pie-graph-info{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-around;
                        align-items: center;
                        button{
                            padding: 0.8em 2em;
                        }
                    }
                    .bar-graph-info{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-around;
                        align-items: center;
                        h1, p{
                            margin: 0;
                        }
                        p{
                            margin: 1em 0 0 0;
                        }
                        button{
                            padding: 0 2em;
                            height: 2.8em;
                        }
                    }
                }
            }
        }

        .main-orders{
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 1.4em;
            overflow: hidden;

            .orders-table{
                display: flex;
                flex-direction: column;
                .orders-table-title{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    button{
                        background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                        border: none;
                        color: white;
                        font-size: 14px;
                        padding: 0.6em 1em;
                        border-radius: 0.4em;
                        margin: 1em 2em 0 2em;
                    }
                    h2{
                        margin: 0.4em 0 0 2em;
                    }
                }
                .orders-table-content{
                    width: 100%;
                    margin-top: 1em;
                    text-align: center;
                    overflow-y: scroll;
                    thead{
                        width:100%;
                        .orders-table-header{
                            height: 2.4em;
                        }
                    }
                    tbody {
                        display:block;
                        max-height: calc(0.64 * var(--prim-widget-height));
                        overflow-y:scroll;
    
                        .orders-table-item{
                            margin: 0.4em 0;
                        }
                    }
                    thead, tbody .orders-table-item {
                        display:table;
                        width:100%;
                        table-layout:fixed;
                    }
                }
            }
        }

        .main-footer{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 1em;
            padding: 1em 0;
        }
    }
}

@media only screen and (max-width: 1150px){
    .main-container{
        .main-content{
            .main-graphs{
                .line-graph, .alt-graph{
                    h1{
                        font-size: 24px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}