.navbar-hidden {
  transform: translateY(-10em);
  transition: 0.2s ease-in-out;
}

.navbar-container {
  transition: 0.2s ease-in-out;
  display: flex;
  position: fixed;
  width: 100vw;
  margin-top: 0.2em;
  height: 4.4em;
  z-index: 999;
}
.navbar-container nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1em;
}
.navbar-container nav .navbar-navigation {
  padding: 0;
}
.navbar-container nav .navbar-navigation img {
  width: 100px;
}
.navbar-container nav .navbar-navigation li {
  display: none;
}
.navbar-container nav .navbar-status .navbar-actions {
  display: none;
}
.navbar-container nav .navbar-status .menu-btn {
  background: #0D0127;
  border: none;
  padding: 0;
  border-radius: 0.4em;
}
.navbar-container .nav-expanded {
  height: 100vh !important;
  transition: ease-in 0.2s;
  background: #1E2243;
  align-items: flex-start;
}
.navbar-container .nav-expanded .navbar-navigation {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: left;
  gap: 8vh;
  height: 100%;
  margin: 0.28em 0;
}
.navbar-container .nav-expanded .navbar-navigation li {
  display: flex;
  align-items: center;
  margin-left: 2em;
  height: 2em;
  border-radius: 1em;
  color: white;
}
.navbar-container .nav-expanded .navbar-navigation li a {
  text-decoration: none;
  color: inherit;
}
.navbar-container .nav-expanded .navbar-status {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: start;
  margin: 0.6em 0;
  height: 100%;
  gap: 9.2vh;
}
.navbar-container .nav-expanded .navbar-status .navbar-actions {
  display: flex;
  flex-direction: column;
  gap: 9.2vh;
  margin-right: 2em;
  align-items: center;
}
.navbar-container .nav-expanded .navbar-status .navbar-actions li {
  list-style: none;
  color: white;
}
.navbar-container .nav-expanded .navbar-status .navbar-actions li a {
  text-decoration: none;
  color: inherit;
}
.navbar-container .nav-expanded .navbar-status .navbar-actions button {
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  color: white;
  font-size: 16px;
  padding: 0.6em 1em;
  border: none;
  border-radius: 0.4em;
  transition: box-shadow 0.6s;
}
.navbar-container .nav-expanded .navbar-status .navbar-actions button:hover {
  box-shadow: 0 0 30px 2px #FE107A;
  transition: box-shadow 0.6s;
}
.navbar-container .nav-expanded .navbar-status .navbar-actions img {
  display: none;
}

@media only screen and (min-width: 700px) {
  .navbar-hidden {
    transform: translateY(-10em);
    transition: 0.2s ease-in-out;
  }
  .navbar-container {
    align-items: center;
    justify-content: center;
    height: auto;
    transition: 0.2s ease-in-out;
  }
  .navbar-container nav {
    width: 74vw;
  }
  .navbar-container nav .navbar-navigation {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    width: 48vw;
    max-width: 32em;
    justify-content: space-between;
  }
  .navbar-container nav .navbar-navigation img {
    margin-right: 1.2em;
    width: 104px;
  }
  .navbar-container nav .navbar-navigation li {
    display: block;
    color: white;
    cursor: pointer;
  }
  .navbar-container nav .navbar-navigation li a {
    text-decoration: none;
    color: inherit;
    transition: 0.5s;
  }
  .navbar-container nav .navbar-navigation li a:hover {
    color: #FE107A;
    transition: 0.5s;
  }
  .navbar-container nav .navbar-status .menu-btn {
    display: none;
  }
  .navbar-container nav .navbar-status .navbar-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    list-style: none;
    color: white;
    white-space: nowrap;
  }
  .navbar-container nav .navbar-status .navbar-actions:hover {
    cursor: pointer;
  }
  .navbar-container nav .navbar-status .navbar-actions li {
    color: white;
    cursor: pointer;
  }
  .navbar-container nav .navbar-status .navbar-actions li a {
    text-decoration: none;
    color: inherit;
  }
  .navbar-container nav .navbar-status .navbar-actions li a:hover {
    color: #FE107A;
    transition: 0.5s;
  }
  .navbar-container nav .navbar-status .navbar-actions button {
    background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
    color: white;
    font-size: 16px;
    padding: 0.6em 1em;
    border: none;
    border-radius: 0.4em;
    transition: box-shadow 0.6s;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
  .navbar-container nav .navbar-status .navbar-actions button:hover {
    box-shadow: 0 0 30px 2px #FE107A;
    transition: box-shadow 0.6s;
  }
  .navbar-container nav .navbar-status .navbar-actions img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 1px solid #FB9758;
  }
}/*# sourceMappingURL=Navbar.css.map */