.blog-content-regular-info-tags {
  display: flex;
  flex-direction: row;
  gap: 0.4em;
  flex-wrap: wrap;
}
.blog-content-regular-info-tags p {
  color: #FE107A;
  background: rgba(254, 16, 123, 0.2274509804);
  padding: 0.2em 0.4em;
  border-radius: 1em;
}

.blog-container {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog-container .blog-footer {
  margin: 4em 0 0 0;
}
.blog-container .blog-header {
  margin: 2em 0 1.2em 0;
  padding: 4em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog-container .blog-header h1 {
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 40px;
  margin: 0;
}
.blog-container .blog-header h2 {
  color: #FB9758;
  margin: 0.4em 0;
}
.blog-container .blog-header p {
  color: rgb(197, 197, 197);
  line-height: 1.8em;
  text-align: center;
  width: 22em;
}
.blog-container .blog-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  width: 80vw;
}
.blog-container .blog-content .blog-content-standard-layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.blog-container .blog-content .blog-content-maincard {
  display: flex;
  flex-direction: row !important;
  background: #151537;
}
.blog-container .blog-content .blog-content-maincard img {
  aspect-ratio: 16/9 !important;
  -o-object-fit: cover;
     object-fit: cover;
  width: 24em !important;
}
.blog-container .blog-content .blog-content-maincard .blog-content-regular-info {
  width: 50vw !important;
}
.blog-container .blog-content .blog-content-maincard .blog-content-regular-info h1 {
  font-size: xx-large !important;
}
.blog-container .blog-content .blog-content-maincard .blog-content-regular-info p {
  font-size: medium !important;
}
.blog-container .blog-content .blog-content-maincard .blog-content-regular-info .blog-content-regular-info-author img {
  aspect-ratio: 1/1 !important;
  -o-object-fit: cover;
     object-fit: cover;
  width: 3.2em !important;
  height: 3.2em !important;
  border-radius: 100%;
}
.blog-container .blog-content .blog-content-maincard:hover {
  background: #151537 !important;
}
.blog-container .blog-content .blog-content-regular {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 1em;
  border-radius: 1em;
  cursor: pointer;
}
.blog-container .blog-content .blog-content-regular .blog-content-regular-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 20em;
}
.blog-container .blog-content .blog-content-regular .blog-content-regular-info .blog-content-regular-info-tags {
  display: flex;
  flex-direction: row;
  gap: 0.4em;
  flex-wrap: wrap;
}
.blog-container .blog-content .blog-content-regular .blog-content-regular-info .blog-content-regular-info-tags p {
  color: #FE107A;
  background: rgba(254, 16, 123, 0.2274509804);
  padding: 0.2em 0.4em;
  border-radius: 1em;
}
.blog-container .blog-content .blog-content-regular .blog-content-regular-info .blog-content-regular-info-author {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}
.blog-container .blog-content .blog-content-regular .blog-content-regular-info .blog-content-regular-info-author img {
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  width: 2.4em;
  height: 2.4em;
  border-radius: 100%;
}
.blog-container .blog-content .blog-content-regular .blog-content-regular-info .blog-content-regular-info-author .blog-content-regular-info-author-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blog-container .blog-content .blog-content-regular img {
  aspect-ratio: 16/9;
  -o-object-fit: cover;
     object-fit: cover;
  width: 20em;
  border-radius: 1em;
}
.blog-container .blog-content .blog-content-regular h1 {
  color: white;
  margin: 0;
  font-size: larger;
}
.blog-container .blog-content .blog-content-regular p {
  color: rgb(197, 197, 197);
  font-size: small;
}
.blog-container .blog-content .blog-content-regular:hover {
  background: rgba(21, 21, 55, 0.5764705882);
}

@media only screen and (max-width: 1150px) {
  .blog-container .blog-header {
    margin: 2.4em 0;
    padding: 2.4em 0;
  }
  .blog-container .blog-content {
    gap: 1em;
  }
  .blog-container .blog-content .blog-content-maincard {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    padding: 0.4em;
  }
  .blog-container .blog-content .blog-content-maincard .blog-content-regular-info {
    width: 78vw !important;
  }
}/*# sourceMappingURL=Blog.css.map */