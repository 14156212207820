.blogpage-container {
  color: white;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 6.4em 0 0 0;
  gap: 2.4em;
}
.blogpage-container .blogpage-header {
  width: 60vw;
}
.blogpage-container .blogpage-header h1 {
  margin: 0;
}
.blogpage-container .blogpage-header .blogpage-header-author {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
}
.blogpage-container .blogpage-header .blogpage-header-author img {
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  width: 3.2em;
  height: 3.2em;
  border-radius: 100%;
}
.blogpage-container .blogpage-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
}
.blogpage-container .blogpage-content P {
  line-height: 1.8em;
  font-size: large;
  color: rgb(224, 224, 224);
}

@media only screen and (max-width: 1150px) {
  .blogpage-container .blogpage-header, .blogpage-container .blogpage-content {
    width: 90vw;
  }
  .blogpage-container .blogpage-content P {
    line-height: 1.4em;
  }
}/*# sourceMappingURL=BlogPage.css.map */