.description-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
}
.description-container .desktop {
  display: none !important;
}
.description-container .description-block {
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 1em;
  gap: 1em;
}
.description-container .description-block .line-span {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.description-container .description-block .line-span .line-span-straight {
  height: 100%;
  width: 0.1em;
  background: linear-gradient(180deg, #FE107A 0%, #FB9758 100%);
}
.description-container .description-block .line-span .line-span-icon {
  color: white;
  margin: 20px 0;
  width: 1.8em;
  height: 1.8em;
}
.description-container .description-block .line-span .line-span-blob {
  position: absolute;
  background: linear-gradient(to right, #FE107A, #FB9758);
  height: 4vh;
  aspect-ratio: 1;
  transform: translate(0, 1em);
  border-radius: 50%;
  z-index: -2;
}
.description-container .description-block .line-span .line-span-blur {
  height: 10em;
  overflow: hidden;
  width: 100vw;
  position: absolute;
  z-index: -1;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
.description-container .description-block .description-block-content {
  height: 100%;
  max-width: 80vw;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.description-container .description-block .description-block-content .description-content h1 {
  color: white !important;
  padding: 0;
  margin: 0;
  font-size: x-large;
}
.description-container .description-block .description-block-content .description-content p {
  line-height: 24px;
  color: #BEB3D8 !important;
}
.description-container .description-block .description-block-content .description-content button {
  background: none;
  border-style: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
}
.description-container .description-block .description-block-content .description-content button:hover {
  text-decoration: underline;
  text-decoration-color: white;
}
.description-container .description-block .description-block-content img {
  width: 85vw;
}

@media only screen and (min-width: 768px) {
  .description-container {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 4em 0;
    align-items: center;
  }
  .description-container .mobile {
    display: none !important;
  }
  .description-container .desktop {
    display: flex !important;
    flex-direction: column;
  }
  .description-container .description-block {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .description-container .description-block .description-block-content-reverse {
    flex-direction: row-reverse !important;
  }
  .description-container .description-block .description-block-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: unset;
    justify-content: center;
    gap: 2em;
  }
  .description-container .description-block .description-block-content .line-span {
    display: flex;
    flex-direction: column;
  }
  .description-container .description-block .description-block-content .line-span .line-span-straight {
    height: 100%;
    width: 0.1em;
  }
  .description-container .description-block .description-block-content .line-span .line-span-icon {
    width: 2em;
    height: 2em;
  }
  .description-container .description-block .description-block-content .line-span .line-span-blob {
    position: absolute;
    background: linear-gradient(to right, #FE107A, #FB9758);
    height: 4vh;
    aspect-ratio: 1;
    transform: translate(0, 1.2em);
    border-radius: 50%;
    z-index: -2;
  }
  .description-container .description-block .description-block-content .line-span .line-span-blur {
    height: 10em;
    transform: translate(0, -4em);
    overflow: hidden;
    width: 20vw;
    position: absolute;
    z-index: -1;
    -webkit-backdrop-filter: blur(12px);
            backdrop-filter: blur(12px);
  }
  .description-container .description-block .description-block-content .description-content {
    width: 50vw;
    max-width: 32em;
  }
  .description-container .description-block .description-block-content .description-content h1 {
    font-size: 38px !important;
    font-weight: 600;
    color: white !important;
    padding: 0;
    margin: 0;
    font-size: x-large;
  }
  .description-container .description-block .description-block-content .description-content p {
    line-height: 28px;
    font-size: 18px;
  }
  .description-container .description-block .description-block-content .description-content button {
    font-size: 18px;
  }
  .description-container .description-block .description-block-content img {
    width: 50vw;
    max-width: 32em;
    padding: 0 0 2em 0;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}/*# sourceMappingURL=Description.css.map */