// background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
// background: #0D0127;
// #FE107A

.description-container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden !important;
    // align-items: center;

    .desktop{
        display: none !important;
    }

    .description-block {
        height: 100%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        margin: 0 1em;
        gap: 1em;

        .line-span{
            display: flex;
            flex-direction: column;
            align-items: center;
            // gap: 1em;

            .line-span-straight{
                height: 100%;
                width: 0.1em;
                background: linear-gradient(180deg, #FE107A 0%, #FB9758 100%);
                // background: rgba(255, 255, 255, 0.20);
            }

            .line-span-icon{
                color: white;
                // margin: 1.4em 0;
                margin: 20px 0;
                width: 1.8em;
                height: 1.8em;
            }
            .line-span-blob{
                position: absolute;
                background: linear-gradient(to right, #FE107A, #FB9758);
                height: 4vh;
                aspect-ratio: 1;
                transform: translate(0, 1em);
                border-radius: 50%;
                z-index: -2;
                // animation: pulse 1.5s infinite;
            }
            .line-span-blur{
                height:10em;
                overflow: hidden;
                width: 100vw;
                position: absolute;
                z-index: -1;
                backdrop-filter: blur(8px);
            }
        }

        .description-block-content{
            height: 100%;
            max-width: 80vw;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            .description-content{
                h1{
                    color: white !important;
                    padding: 0;
                    margin: 0;
                    font-size: x-large;
                }
                p{
                    line-height: 24px;
                    color: #BEB3D8 !important;
                }
                button{
                    background: none;
                    border-style: none;
                    color: white;
                    font-size: 16px;
                    font-weight: 500;

                    &:hover{
                        text-decoration: underline;
                        text-decoration-color: white;
                    }
                }
            }
            img{
                width: 85vw;
            }
        }
    }
}

@media only screen and (min-width: 768px){
    .description-container{
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: 4em 0;
        align-items: center;
    
        .mobile{
            display: none !important;
        }
        .desktop{
            display: flex !important;

            flex-direction: column;
        }
    
        .description-block {
            width: 100vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .description-block-content-reverse{
                flex-direction: row-reverse !important;
            }
    
            .description-block-content{
                // margin: 0 10em;
                height: 100%;
                // width: 50em;
                display: flex;
                flex-direction: row;
                align-items: unset;
                // align-items: center;
                justify-content: center;
                gap: 2em;

                .line-span{
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    // gap: 1em;
        
                    .line-span-straight{
                        height: 100%;
                        width: 0.1em;
                        // background: linear-gradient(180deg, #FE107A 0%, #FB9758 100%) !important;
                        // background: rgba(255, 255, 255, 0.20);
                    }
        
                    .line-span-icon{
                        // margin: 1.4em 0;
                        // margin: 0;
                        width: 2em;
                        height: 2em;
                        // background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                        // animation: pulse 1.5s infinite;
                    }
                    .line-span-blob{
                        position: absolute;
                        background: linear-gradient(to right, #FE107A, #FB9758);
                        height: 4vh;
                        aspect-ratio: 1;
                        // transform: none;
                        transform: translate(0, 1.2em);
                        border-radius: 50%;
                        z-index: -2;
                    }
                    .line-span-blur{
                        height:10em;
                        transform: translate(0, -4em);
                        overflow: hidden;
                        width: 20vw;
                        position: absolute;
                        z-index: -1;
                        backdrop-filter: blur(12px);
                    }
                }
    
                .description-content{
                    width: 50vw;
                    max-width: 32em;
                    h1{
                        font-size: 38px !important;
                        font-weight: 600;
                        color: white !important;
                        padding: 0;
                        margin: 0;
                        font-size: x-large;
                    }
                    p{
                        line-height: 28px;
                        font-size: 18px;
                        // max-width: 40vw;
                    }
                    button{
                        font-size: 18px;
                    }
                }

                img{
                    width: 50vw;
                    max-width: 32em;
                    padding: 0 0 2em 0;
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
    transform: scale(0.9);
    }
    90% {
      transform: scale(1);
    //   box-shadow: 0 0 20px 2px #FE107A;
    //   box-shadow: 0 1 0 10px rgba(90, 153, 212, 0);
    }
      100% {
      transform: scale(0.9);
    //   box-shadow: 0 0 20px 2px #FE107A;
    //   box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
    }
}