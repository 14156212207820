.sidebar-container{
    height: 98vh;
    margin-left: 0.6em;
    width: 22.8em;
    max-width: 18.88em;
    background: #151537;
    border-radius: 1em;
    // border-right: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: ease-in-out 0.2s;
    white-space: nowrap;
    overflow: hidden;

    .sidebar-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .sidebar-logo{
            width: 140px;
            margin: 2em 0 0 0;
        }
        .sidebar-items{
            margin-top: 2em;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1em;
            width: 100%;
            .active-item{
                font-weight: bold;
                border-right: 4px solid #FB9758 !important;
                border-radius: 4px;
            }
            .exit-button{
                color: rgb(228, 21, 21) !important;
                font-weight: bold;
                &:hover{
                    cursor: pointer;
                    border-right: 4px solid rgb(228, 21, 21);
                    border-radius: 4px;
                }
            }
            .active-item.exit-button{
                border-right: 4px solid rgb(228, 21, 21) !important;
            }
            button{
                background: none;
                width: 80%;
                padding: 0.4em 1em;
                border: none;
                color: white;
                text-align: left;
                font-size: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1em;
                &:hover{
                    cursor: pointer;
                    border-right: 4px solid #888888b9;
                    border-radius: 4px;
                }
                img{
                    width: 1.2em;
                }
            }
        }
    }

    .sidebar-card{
        background: black;
        margin: 2em 1em;
        padding: 1em;
        height: 24%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        border-radius: 1em;
        background: linear-gradient(300deg, #FE107A 0%, #FB9758 100%);
        white-space: normal;
        h2{
            margin: 0;
        }
        button{
            color: white;
            background: rgb(255, 255, 255, 0.2);
            border: none;
            font-size: large;
            padding: 0.4em 0;
            width: 80%;
            border-radius: 0.4em;
        }
    }
}

@media only screen and (max-width: 1110px){
    .sidebar-container{
        display: none;
    }
}