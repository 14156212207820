.payment-container {
  padding: 4em 0 0 0;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background: #0D0127;
}
.payment-container h1 {
  line-height: 0;
}
.payment-container h3 {
  display: none;
}
.payment-container .pricing-cards {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payment-container .pricing-cards h1 {
  text-align: center;
  font-weight: 600;
}
.payment-container .pricing-cards h2 {
  font-weight: 400;
  line-height: 0;
  text-align: center;
}
.payment-container .pricing-cards p {
  text-align: center;
}
.payment-container .pricing-cards div {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 1em;
  background: white;
  -webkit-backdrop-filter: blur(17.5px);
          backdrop-filter: blur(17.5px);
  padding: 2em 0;
  width: 90%;
}
.payment-container .pricing-cards div ul {
  list-style-type: none;
}
.payment-container .pricing-cards div ul .check-list {
  background: url("../../usage/assets/icons/check.png") no-repeat left top;
  height: 34px;
  padding-left: 2em;
}
.payment-container button {
  width: 100%;
  display: block;
  border: none;
  color: white;
  font-weight: 200;
  padding: 1em 0;
  white-space: nowrap;
  margin-top: 1rem;
  overflow: hidden;
  background: #521df0c3;
  color: white;
  cursor: pointer;
}
.payment-container .pricing-cards .business-best {
  overflow: hidden;
  background: rgb(252, 70, 70);
  background: radial-gradient(circle, rgb(252, 70, 70) 0%, rgb(48, 79, 227) 100%);
  -webkit-backdrop-filter: blur(17.5px);
          backdrop-filter: blur(17.5px);
  display: flex;
  justify-content: center;
  justify-content: first baseline;
  height: 28em;
  align-items: center;
}
.payment-container .pricing-cards .business-best h4 {
  font-size: 25px;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 0;
}
.payment-container .pricing-cards .business-best .pricing-business {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  border-radius: 1em;
  background: white;
  -webkit-backdrop-filter: blur(17.5px);
          backdrop-filter: blur(17.5px);
  padding: 2em 0;
  width: 95%;
  bottom: 0px;
}

@media only screen and (min-width: 768px) {
  .payment-container {
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  .payment-container h3 {
    text-align: center;
    font-weight: 400;
    color: grey;
  }
  .payment-container .price-deco-pink {
    position: absolute;
    background: pink;
    height: 10em;
    width: 10em;
    filter: blur(4em);
    -webkit-animation: scale-up-center 20s cubic-bezier(0.39, 0.575, 0.565, 1) infinite both;
            animation: scale-up-center 20s cubic-bezier(0.39, 0.575, 0.565, 1) infinite both;
    mix-blend-mode: multiply;
  }
  .payment-container .price-deco-blue {
    position: absolute;
    background: blue;
    height: 10em;
    width: 10em;
    filter: blur(6em);
    -webkit-animation: scale-up-center 30s cubic-bezier(0.39, 0.575, 0.565, 1) infinite both;
            animation: scale-up-center 30s cubic-bezier(0.39, 0.575, 0.565, 1) infinite both;
    mix-blend-mode: multiply;
  }
  @-webkit-keyframes scale-up-center {
    0% {
      transform: translate(0em, 0em) scale(1);
    }
    50% {
      transform: translate(-40em, 0em) scale(1.1);
    }
    66% {
      transform: translate(20em, 10em) scale(0.9);
    }
    100% {
      transform: translate(0px, 0px) scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      transform: translate(0em, 0em) scale(1);
    }
    50% {
      transform: translate(-40em, 0em) scale(1.1);
    }
    66% {
      transform: translate(20em, 10em) scale(0.9);
    }
    100% {
      transform: translate(0px, 0px) scale(1);
    }
  }
  .payment-container .pricing-cards {
    width: 80%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-radius: 2em;
  }
  .payment-container .pricing-cards .pricing-starter {
    justify-content: center;
    transition: 0.2s;
  }
  .payment-container .pricing-cards .pricing-starter:hover {
    height: 100%;
    transition: 0.2s;
  }
  .payment-container .pricing-cards .pricing-enterprise {
    justify-content: center;
    transition: 0.2s;
  }
  .payment-container .pricing-cards .pricing-enterprise:hover {
    height: 100%;
    transition: 0.2s;
  }
  .payment-container .pricing-cards div {
    background: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(17.5px);
            backdrop-filter: blur(17.5px);
    height: 80%;
  }
  .payment-container .pricing-cards div button:hover {
    background: #ff8181;
  }
}/*# "This tag removes the mapping" sourceMappingURL=Pricing.css.map */