:root{
    overflow-x: hidden;
}

@media only screen and (min-width: 768px){
    .elements-routed{
        max-height: 100%;
    }
    #blob{
        position: absolute;
        background: linear-gradient(to right, #FE107A, #FB9758);
        height: 44vh;
        aspect-ratio: 1;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        border-radius: 50%;
        animation: rotate 20s infinite;
        // filter: blur(100px);
        z-index: -2;
    }
    #blur{
        // height:200%;
        height: 160%;
        overflow: hidden;
        width: 100vw;
        position: absolute;
        z-index: -1;
        backdrop-filter: blur(200px);
    }
}