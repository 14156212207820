body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-family:"Inter", sans-serif;
  --active-blue: #5278FF;
  --common-white: #F2F2F2;

  --grad-col-1: #FE107A;
  --grad-col-2: #FB9758;
  --sig-blue: #1E2243;
  --sig-variant: #151537;
  --dark-background: #0D0127;

  background: #0D0127;

}

* {
  font-family: var(--font-family);
}

.small-text {
  color: rgb(180, 180, 180);
}

.sub-text {
  color: rgb(197, 193, 193);
}

.gradient-text {
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gradient-button{
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%) !important;
  border: none !important;
  height: 2.4em !important;
  font-size: large !important;
  transition: box-shadow 0.6s;
}
.gradient-button:hover {
  cursor: pointer;
  box-shadow: 0 0 20px 2px #FE107A;
  transition: box-shadow 0.6s;
}

.error-subtext{
  color: #FE107A;
  font-weight: bold;
}

.search-input{
  background: var(--sig-blue);
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;
  padding: 0.4em 0.4em;
  border-radius: 1em;
  gap: 0.2em;
  user-select: none;
}
.search-input img {
  width: 24px !important;
}
.search-input input {
  background: none;
  border: none;
  color: white;
  outline: none;
}

::selection {
  background: #FE107A;
}