.plans-container {
  width: 100vw;
  height: 100%;
  background: #0D0127;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2em 0;
}
.plans-container h1 {
  font-size: 28px;
  margin-bottom: 0;
}
.plans-container .plans-subheading {
  color: #BEB3D8;
  line-height: 1.5em;
  max-width: 80vw;
}
.plans-container .plans-cards {
  display: flex;
  flex-direction: column;
  gap: 2em;
  text-align: start;
}
.plans-container .plans-cards .plan-63615ab136d8763ae46acd5b {
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
}
.plans-container .plans-cards .plan-63615ab136d8763ae46acd5b p {
  color: white !important;
}
.plans-container .plans-cards .plan-63615ab136d8763ae46acd5b li {
  color: white !important;
}
.plans-container .plans-cards .plan-63615ab136d8763ae46acd5b h1 h2 {
  display: none;
}
.plans-container .plans-cards .plan-63615ab136d8763ae46acd5b span {
  background: white !important;
}
.plans-container .plans-cards .plans-plan {
  display: flex;
  flex-direction: column;
  border: 1px solid #3F2F66;
  border-radius: 1em;
  padding: 1em;
  gap: 1em;
}
.plans-container .plans-cards .plans-plan .plans-plan-header {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.plans-container .plans-cards .plans-plan .plans-plan-header h2 {
  font-weight: 400;
  margin-bottom: 0;
}
.plans-container .plans-cards .plans-plan .plans-plan-header h1 {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
  font-size: 36px;
  margin: 0;
  align-items: end;
}
.plans-container .plans-cards .plans-plan .plans-plan-header h1 h2 {
  font-size: 20px;
  color: #BEB3D8;
  margin: 0.2em 0;
}
.plans-container .plans-cards .plans-plan .plans-plan-header p {
  margin-top: 0;
}
.plans-container .plans-cards .plans-plan p {
  color: #BEB3D8;
}
.plans-container .plans-cards .plans-plan span {
  width: 100%;
  height: 1px;
  background: #3F2F66;
}
.plans-container .plans-cards .plans-plan button {
  width: 60%;
  height: 3.4em;
  font-size: 16px;
  margin: 1em 0;
  color: white;
  background: #2C2554;
  border: none;
  border-radius: 2em;
}
.plans-container .plans-cards .plans-plan button:hover {
  text-decoration: underline;
  cursor: pointer;
}
.plans-container .plans-cards .plans-plan ul {
  list-style-type: none;
  padding: 0 0.2em;
  display: flex;
  flex-direction: column;
  gap: 1.4em;
}
.plans-container .plans-cards .plans-plan ul li {
  display: flex;
  flex-direction: row;
  gap: 1em;
  color: #BEB3D8;
}
.plans-container .plans-cards .plans-plan ul li .checkmark-icon {
  color: #FB9758;
}

@media only screen and (min-width: 768px) {
  .plans-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 0;
    margin: 6em 0;
    gap: 1em;
  }
  .plans-container h1 {
    font-size: 42px;
  }
  .plans-container .plans-subheading {
    display: block;
    text-align: center;
    font-weight: 400;
    color: #BEB3D8;
    font-size: 16px;
    max-width: 45em;
  }
  .plans-container .plans-cards {
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .plans-container .plans-cards .plan-63615ab136d8763ae46acd5b {
    margin-bottom: 2em;
  }
  .plans-container .plans-cards .plans-plan .plans-plan-header h1 {
    font-size: 40px;
    margin: 0;
  }
  .plans-container .plans-cards .plans-plan button {
    margin-bottom: 0;
  }
  .plans-container .plans-cards div {
    width: auto;
    max-width: 20vw;
    width: 22em;
  }
  .plans-container .plans-cards div h1 {
    margin: 0;
    padding: 0;
  }
}/*# sourceMappingURL=Cards.css.map */