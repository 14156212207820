:root {
  --db-width-var: 78vw;
  --prim-widget-height: 20em;
}

.medium-widget {
  width: 48%;
  height: var(--prim-widget-height);
  background: #151537;
  border-radius: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.small-text {
  color: rgb(180, 180, 180);
}

.timer-button {
  color: white;
  background: rgba(255, 255, 255, 0.247);
  border: none;
  border-radius: 0.4em;
  padding: 0.8em 0;
}

.download-button {
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  border: none;
  color: white;
  font-size: 12px;
  padding: 0.6em 0.8em;
  border-radius: 0.4em;
}

.dashboard-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #0D0127;
  gap: 1em;
  overflow: hidden;
  color: white;
}
.dashboard-container .dashboard-disclaimer {
  display: none;
}

@media only screen and (max-width: 1600px) {
  :root {
    --db-width-var: 76vw ;
  }
}
@media only screen and (max-width: 1420px) {
  :root {
    --db-width-var: 74vw;
    --prim-widget-height: 18em;
  }
}
@media only screen and (max-width: 1280px) {
  :root {
    --db-width-var: 72vw;
    --prim-widget-height: 16em;
  }
}
@media only screen and (max-width: 1150px) {
  :root {
    --db-width-var: 70vw;
    --prim-widget-height: 16em;
  }
}
@media only screen and (max-width: 1110px) {
  :root {
    --db-width-var: 94vw ;
  }
}
@media only screen and (max-width: 800px) {
  .dashboard-container * {
    display: none !important;
  }
  .dashboard-container .dashboard-disclaimer {
    display: contents !important;
  }
}/*# sourceMappingURL=Dashboard.css.map */