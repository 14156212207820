.contact-container{
    display: flex;
    flex-direction: column-reverse;
    width: 100vw;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    video{
        width: 90vw;
        margin: 4em 0 0 0;
    }

    .contact-form{
        display: flex;
        flex-direction: column;
        width: 90vw;
        align-items: center;
        text-align: center;

        .contact-form-sub{
            font-weight: 600;
            font-size: 20px;
            padding: 0;
        }
        h1{
            font-size: 30px;
            color: white;
            padding: 0;
            margin: 0;
        }
        .contact-form-subtext{
            color: #BEB3D8;
            line-height: 1.5em;
        }

        .contact-form-elements{
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 1em;

            input{
                width: 100%;
                height: 3em;
                background: #151537;
                border: none;
                border-radius: 0.5em;
                font-size: 16px;
                color: white;
            }
            textarea{
                width: 100%;
                height: 8em;
                background: #151537;
                border: none;
                border-radius: 0.5em;
                font-size: 16px;
                color: white;
            }
        }
        .contact-submit{
            color: white;
            width: 100%;
            border-radius: 0.5em;
            margin-top: 1em;
        }
    }

    img{
        // position: relative;
        width: 120vw;
        transform: translate(-5em, 5em);
    }

}

@media only screen and (min-width: 768px){
    .contact-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-evenly;
        justify-content: center;
        gap: 8em;
        margin:0 0 4em 0;

        video{
            width: 30vw;
            max-width: 35em;
            margin: 0;
            border-radius: 1em;
        }

        .span-contact-divider{
            width: 2em;
            height: 2em;
            // background: red;
        }

        .contact-form{
            width: 30em;
            align-items: unset;
            text-align: start;

            h1{
                font-size: 40px;
            }

            .contact-form-subtext{
                line-height: 1.6em;
            }

        }

        img{
            position: absolute;
            transform: translate(-30vw, 20vh);
            width: 45vw;
            z-index: -1;
            overflow: hidden;
        }
    }
}

// @media only screen and (max-width: 1080px){

// }