.services-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}
.services-container .services-content {
  margin: 8em 0 0 1em;
  display: flex;
  flex-direction: column;
  gap: 1.4em;
}/*# sourceMappingURL=Services.css.map */