.about-container{
    width: 100vw;
    height: 100%;
    background: #0D0127;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    .about-content{
        margin-top: 6em;
        display: flex;
        flex-direction: column;
        //gap: 2em;
        div{
            width: 80vw;
            text-align: center;
            display: flex;
            flex-direction: column;
            h1{
                font-weight: 600;
                background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                margin: 0.8em 0;
            }
            p{
                text-align: left;
                line-height: 1.8em;
                ul{
                    padding: 0;
                    li{
                        text-align: left;
                    }
                }
            }
            ul{
                display: flex;
                flex-direction: column;
                gap: 1em;
            }
        }
    }
}

@media only screen and(min-width: 768px){
    .about-container{
        .about-content{
            div{
                width: 54vw;
                text-align: center;
                p{
                    text-align: left;
                    line-height: 1.8em;
                    font-size: 18px;
                }
            }
        }
    }
}