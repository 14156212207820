.promotional-container{
    width: 100vw;
    height: 100%;
    background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
    padding: 2em 0;
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .desktop{
        display: none;
    }

    .promotional-content{
        display: flex;
        flex-direction: column;
        width: 80vw;
        color: white;

        .promotional-subheading{
            font-weight:bold;
        }

        h1{
            // margin: 0 0 0.5em 0;
            margin: 0;
            // text-wrap: nowrap;
        }

        P{
            line-height: 1.6em;
        }

        button{
            background: #0D1435;
            border: none;
            color: white;
            height: 3.5em;
            width: 15em;
            border-radius: 1em;
            font-size: 15px;
        }
    }

    img{
        transform: translate(-10% ,0);
    }
}

@media only screen and (min-width: 768px){
    .promotional-container{
        display: flex;
        flex-direction: row;
        max-height: 40vh;
        overflow: hidden;

        .desktop{
            display: block;
            // position: absolute;
        }

        .mobile{
            display: none;
        }

        .promotional-content{
            // transform: translateX(20%);
            margin-left: 20vw;
            max-width: 30vw;
            gap: 1em;
            h1{
                font-size: 40px;
            }
            p{
                max-width: 30em;
            }
        }

        // .promotional-spacer{
        //     height: 2em;
        //     width: 2em;
        //     background: red;
        // }

        img{
            width: 45vw;
            // position: absolute;
            transform: translate(5%, 10%);
            // z-index: -1;
        }
    }
}