.main-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}
.main-container .main-content {
  margin: 8em 0 0 1em;
  display: flex;
  flex-direction: column;
  gap: 1.4em;
}
.main-container .main-content .main-cards {
  display: flex;
  flex-direction: row;
  gap: 1.8em;
}
.main-container .main-content .main-cards .card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #151537;
  padding: 1.4em 1.8em;
  gap: 1em;
  border-radius: 1em;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.main-container .main-content .main-cards .card img {
  width: 34px;
  height: 34px;
  padding: 0.8em;
  background: #1E2243;
  border-radius: 100%;
}
.main-container .main-content .main-cards .card div {
  display: flex;
  flex-direction: column;
}
.main-container .main-content .main-cards .card div p, .main-container .main-content .main-cards .card div h2 {
  margin: 0;
}
.main-container .main-content .main-cards .card div h2 {
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
}
.main-container .main-content .main-graphs {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1.4em;
}
.main-container .main-content .main-graphs .no-data {
  position: absolute;
}
.main-container .main-content .main-graphs .line-graph {
  justify-content: space-evenly;
}
.main-container .main-content .main-graphs .line-graph .line-graph-item {
  height: 90% !important;
  width: 70% !important;
  margin: 1em 1em 1em 0;
}
.main-container .main-content .main-graphs .line-graph .line-graph-info {
  display: flex;
  flex-direction: column;
  margin: 1em;
  height: 100%;
  justify-content: space-around;
}
.main-container .main-content .main-graphs .line-graph .line-graph-info button {
  width: 6.8em;
  margin-top: 0.4em;
}
.main-container .main-content .main-graphs .line-graph .line-graph-info h1 {
  margin: 0.8em 0 0 0;
}
.main-container .main-content .main-graphs .line-graph .line-graph-info p {
  margin: 0;
}
.main-container .main-content .main-graphs .line-graph .line-graph-info p:hover {
  text-decoration: underline;
  cursor: pointer;
}
.main-container .main-content .main-graphs .alt-graph {
  justify-content: space-between;
  background: none;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph, .main-container .main-content .main-graphs .alt-graph .pie-graph {
  background: #151537;
  width: 48.6%;
  height: 100%;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph .pie-item-container, .main-container .main-content .main-graphs .alt-graph .pie-graph .pie-item-container {
  width: 12em;
  height: 12em;
  overflow: visible;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph .bar-item, .main-container .main-content .main-graphs .alt-graph .pie-graph .bar-item {
  margin: 1em 0;
  height: 80% !important;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph .pie-graph-info, .main-container .main-content .main-graphs .alt-graph .pie-graph .pie-graph-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph .pie-graph-info button, .main-container .main-content .main-graphs .alt-graph .pie-graph .pie-graph-info button {
  padding: 0.8em 2em;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph .bar-graph-info, .main-container .main-content .main-graphs .alt-graph .pie-graph .bar-graph-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph .bar-graph-info h1, .main-container .main-content .main-graphs .alt-graph .bar-graph .bar-graph-info p, .main-container .main-content .main-graphs .alt-graph .pie-graph .bar-graph-info h1, .main-container .main-content .main-graphs .alt-graph .pie-graph .bar-graph-info p {
  margin: 0;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph .bar-graph-info p, .main-container .main-content .main-graphs .alt-graph .pie-graph .bar-graph-info p {
  margin: 1em 0 0 0;
}
.main-container .main-content .main-graphs .alt-graph .bar-graph .bar-graph-info button, .main-container .main-content .main-graphs .alt-graph .pie-graph .bar-graph-info button {
  padding: 0 2em;
  height: 2.8em;
}
.main-container .main-content .main-orders {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.4em;
  overflow: hidden;
}
.main-container .main-content .main-orders .orders-table {
  display: flex;
  flex-direction: column;
}
.main-container .main-content .main-orders .orders-table .orders-table-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.main-container .main-content .main-orders .orders-table .orders-table-title button {
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  border: none;
  color: white;
  font-size: 14px;
  padding: 0.6em 1em;
  border-radius: 0.4em;
  margin: 1em 2em 0 2em;
}
.main-container .main-content .main-orders .orders-table .orders-table-title h2 {
  margin: 0.4em 0 0 2em;
}
.main-container .main-content .main-orders .orders-table .orders-table-content {
  width: 100%;
  margin-top: 1em;
  text-align: center;
  overflow-y: scroll;
}
.main-container .main-content .main-orders .orders-table .orders-table-content thead {
  width: 100%;
}
.main-container .main-content .main-orders .orders-table .orders-table-content thead .orders-table-header {
  height: 2.4em;
}
.main-container .main-content .main-orders .orders-table .orders-table-content tbody {
  display: block;
  max-height: calc(0.64 * var(--prim-widget-height));
  overflow-y: scroll;
}
.main-container .main-content .main-orders .orders-table .orders-table-content tbody .orders-table-item {
  margin: 0.4em 0;
}
.main-container .main-content .main-orders .orders-table .orders-table-content thead, .main-container .main-content .main-orders .orders-table .orders-table-content tbody .orders-table-item {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.main-container .main-content .main-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1em;
  padding: 1em 0;
}

@media only screen and (max-width: 1150px) {
  .main-container .main-content .main-graphs .line-graph h1, .main-container .main-content .main-graphs .alt-graph h1 {
    font-size: 24px;
  }
  .main-container .main-content .main-graphs .line-graph p, .main-container .main-content .main-graphs .alt-graph p {
    font-size: 14px;
  }
}/*# sourceMappingURL=Main.css.map */