.blog-content-regular-info-tags{
    display: flex;
    flex-direction: row;
    gap: 0.4em;
    flex-wrap: wrap;
    p{
        color: #FE107A;
        background:#fe107b3a;
        padding: 0.2em 0.4em;
        border-radius: 1em;
    }
}

.blog-container{
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .blog-footer{
        margin: 4em 0 0 0;
    }
    .blog-header{
        margin: 2em 0 1.2em 0;
        padding: 4em 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-size: 40px;
            margin: 0;
        }
        h2{
            color: #FB9758;
            margin: 0.4em 0;
        }
        p{
            color: rgb(197, 197, 197);
            line-height: 1.8em;
            text-align: center;
            width: 22em;
        }
    }
    .blog-content{
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: center;
        width: 80vw;
        .blog-content-standard-layout{
            display: flex;
            flex-direction: row;
            // align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
        }
        .blog-content-maincard{
            display: flex;
            flex-direction: row !important;
            background:#151537;
            img{
                aspect-ratio: 16 / 9 !important;
                object-fit: cover;
                width: 24em !important;
            }
            .blog-content-regular-info{
                width: 50vw !important;
                h1{
                    font-size:xx-large !important;
                }
                p{
                    font-size: medium !important;
                }
                .blog-content-regular-info-author{
                    img{
                        aspect-ratio: 1 / 1 !important;
                        object-fit: cover;
                        width: 3.2em !important;
                        height: 3.2em !important;
                        border-radius: 100%;
                    }
                }
            }
            &:hover{
                background:#151537 !important;
            }
        }
        .blog-content-regular{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1em;
            padding: 1em;
            border-radius: 1em;
            cursor: pointer;
            .blog-content-regular-info{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                width: 20em;
                .blog-content-regular-info-tags{
                    display: flex;
                    flex-direction: row;
                    gap: 0.4em;
                    flex-wrap: wrap;
                    p{
                        color: #FE107A;
                        background:#fe107b3a;
                        padding: 0.2em 0.4em;
                        border-radius: 1em;
                    }
                }
                .blog-content-regular-info-author{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1em;
                    img{
                        aspect-ratio: 1 / 1;
                        object-fit: cover;
                        width: 2.4em;
                        height: 2.4em;
                        border-radius: 100%;
                    }
                    .blog-content-regular-info-author-text{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
            img{
                aspect-ratio: 16 / 9;
                object-fit: cover;
                width: 20em;
                // width: 100%;
                border-radius: 1em;
            }
            h1{
                color: white;
                margin: 0;
                font-size: larger;
            }
            p{
                color: rgb(197, 197, 197);
                font-size: small;
            }
            &:hover{
                // background:#fb965810;
                background:#15153793;
            }
        }
    }
}

@media only screen and (max-width: 1150px){
    .blog-container{
        .blog-header{
            margin: 2.4em 0;
            padding: 2.4em 0;
        }
        .blog-content{
            gap: 1em;
            .blog-content-maincard{
                display: flex;
                flex-direction: column !important;
                align-items: center;
                // width: 80vw !important;
                padding: 0.4em;
                .blog-content-regular-info{
                    width: 78vw !important;
                }
            }
        }
    }
}