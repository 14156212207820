.sidebar-container {
  height: 98vh;
  margin-left: 0.6em;
  width: 22.8em;
  max-width: 18.88em;
  background: #151537;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: ease-in-out 0.2s;
  white-space: nowrap;
  overflow: hidden;
}
.sidebar-container .sidebar-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar-container .sidebar-content .sidebar-logo {
  width: 140px;
  margin: 2em 0 0 0;
}
.sidebar-container .sidebar-content .sidebar-items {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
}
.sidebar-container .sidebar-content .sidebar-items .active-item {
  font-weight: bold;
  border-right: 4px solid #FB9758 !important;
  border-radius: 4px;
}
.sidebar-container .sidebar-content .sidebar-items .exit-button {
  color: rgb(228, 21, 21) !important;
  font-weight: bold;
}
.sidebar-container .sidebar-content .sidebar-items .exit-button:hover {
  cursor: pointer;
  border-right: 4px solid rgb(228, 21, 21);
  border-radius: 4px;
}
.sidebar-container .sidebar-content .sidebar-items .active-item.exit-button {
  border-right: 4px solid rgb(228, 21, 21) !important;
}
.sidebar-container .sidebar-content .sidebar-items button {
  background: none;
  width: 80%;
  padding: 0.4em 1em;
  border: none;
  color: white;
  text-align: left;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}
.sidebar-container .sidebar-content .sidebar-items button:hover {
  cursor: pointer;
  border-right: 4px solid rgba(136, 136, 136, 0.7254901961);
  border-radius: 4px;
}
.sidebar-container .sidebar-content .sidebar-items button img {
  width: 1.2em;
}
.sidebar-container .sidebar-card {
  background: black;
  margin: 2em 1em;
  padding: 1em;
  height: 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 1em;
  background: linear-gradient(300deg, #FE107A 0%, #FB9758 100%);
  white-space: normal;
}
.sidebar-container .sidebar-card h2 {
  margin: 0;
}
.sidebar-container .sidebar-card button {
  color: white;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  font-size: large;
  padding: 0.4em 0;
  width: 80%;
  border-radius: 0.4em;
}

@media only screen and (max-width: 1110px) {
  .sidebar-container {
    display: none;
  }
}/*# sourceMappingURL=Sidebar.css.map */