.infobar-container{
    display: flex;
    flex-direction: row;
    margin: 2em 0;
    padding: 0.4em 0.4em;
    border-radius: 1em;
    align-items: center;
    position: absolute;
    justify-content: space-between;
    width: var(--db-width-var);
    backdrop-filter: blur(4px);
    // overflow-x: hidden;
    .infobar-heavy-text{
        font-weight: bold;
    }
    .infobar-page{
        display: flex;
        flex-direction: column;
        h1,p{
            margin: 0;
            &:hover{
                cursor: pointer;
            }
        }
        span:hover{
            text-decoration: underline;
        }
    }
    .infobar-actions{
        background: #151537;
        padding: 0.6em 0.6em;
        border-radius: 2em;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
        img{
            width: 20px;
            user-select: none;
        }
        .infobar-avatar{
            margin: 0 0.4em 0 0;
            padding: 0;
            background: #FB9758;
            user-select: none;
            width: 2em;
            height: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
        }
        .infobar-input{
            background: #0D0127;
            display: flex;
            flex-direction: row;
            align-items: left;
            justify-content: center;
            padding: 0.4em 0.4em;
            border-radius: 1em;
            gap: 0.2em;
            user-select: none;
            img{
                width: 24px;
            }
            input{
                background: none;
                border: none;
                color: white;
                outline: none;
            }
        }

    }
    .infobar-details-popup{
        position: absolute;
        right: 0;
        top: 4em;
        background: #1E2243;
        width: 12em;
        display: flex;
        flex-direction: column;
        border-radius: 1em;
        overflow: hidden;
        user-select: none;
        z-index: 100;
        .details-popup-header{
            font-weight: bold;
            color: white;
            padding: 0.4em;
            white-space: nowrap;
            color: white !important;
        }
        span{
            width: 100%;
            height: 1px;
            background: rgba(88, 85, 85, 0.685);
        }
        p{
            color: rgb(218, 218, 218);
            margin: 0.4em 1em;
            cursor: pointer;
            user-select: none;
            transition: ease-in-out 0.4s;
            &:hover{
                transition: ease-in-out 0.4s;
                color: #FB9758;
            }
        }
        .logout-button{
            color: red !important;
        }
    }
    .infobar-notis-popup{
        position: absolute;
        right: 0;
        top: 4em;
        background: #1E2243;
        width: 24em;
        max-height: 38vh;
        display: flex;
        flex-direction: column;
        border-radius: 1em;
        overflow-x: hidden;
        overflow-y: scroll;
        cursor: pointer;
        user-select: none;
        z-index: 100;

        .notis-header{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            p{
                margin: 1em 1em 0 1em;
            }
            .notis-read{
                color: rgb(74, 74, 255);
            }
        }
        .notis-none{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 2em 0;
        }
        .notis-noti{
            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 1em 0;
            align-items: center;
            gap: 1em;
            img{
                width: 2.2em;
                padding: 0.8em;
                background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                border-radius: 1em;
                margin: 0 0 0 1em;
            }
            div{
                display: flex;
                flex-direction: column;
                P{
                    margin: 0;
                }
            }
        }
    }
    .hidden{
        display: none;
    }
}

@media only screen and (max-width: 1110px){
    .infobar-container{
        margin: 2em 1em;
    }
}