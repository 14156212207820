.terms-container{
    width: 100vw;
    height: 100%;
    background: #0D0127;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    .terms-content{
        margin-top: 6em;
        display: flex;
        flex-direction: column;
        h1{
            text-align: center;
        }
        div{
            width: 84vw;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 0.2em;
            margin-top: 1em;
            h2{
                text-align: left;
                font-weight: 600;
                background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                margin: 0;
                //margin: 0.8em 0;
            }
            p{
                text-align: left;
                line-height: 1.8em;
            }
            span{
                color: rgba(255, 255, 255, 0.644);
                text-align: center;
                margin-bottom: 1em;
            }
        }
    }
}

@media only screen and(min-width: 768px){
    .terms-container{
        .terms-content{
            div{
                width: 54vw;
                text-align: center;
                p{
                    text-align: left;
                    line-height: 1.8em;
                    font-size: 16px;
                }
            }
        }
    }
}