
#payment-form{
  max-width: 700px;
  border: #F6F9FC solid 1px;
  /* border-radius: var(--radius); */
  border-radius: 0.4em;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
  background: #1E2243;
}

form{
  border: none !important;
}

.pay-btn{
  background: #FE107A !important;
  border-radius: 0.4em;
}

#submit #info {
  width: 14px;
  margin-left: 0.4em;
}
#submit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0A253C;
  color: #00D924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size:0.7em;
}

#payment-message {
  color: white;
}

#payment-element{

  color: white !important;
  /* background: #00D924 !important; */
}

@media screen and (min-width: 600px) {
  #payment-form {
    width: 500px;
  }
}