.navbar-hidden{
    transform: translateY(-10em);
    transition: 0.2s ease-in-out;
}
.navbar-container{
    transition: 0.2s ease-in-out;
    display: flex;
    position: fixed;
    width: 100vw;
    // background: #1E2243;
    margin-top: 0.2em;
    height: 4.4em;
    z-index: 999;
    nav{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 1em;
        .navbar-navigation{
            padding: 0;
            img{
                width: 100px;
            }
            li{
                display: none;
            }
        }
        .navbar-status{
            .navbar-actions{
                display: none;
            }
            .menu-btn{
                background: #0D0127;
                border: none;
                padding: 0;
                border-radius: 0.4em;
            }
        }
    }
    .nav-expanded{
        height: 100vh !important;
        transition: ease-in 0.2s;
        background: #1E2243;
        align-items:flex-start;
        .navbar-navigation{
            display: flex;
            flex-direction: column;
            justify-content:baseline;
            align-items:left;
            gap: 8vh;
            height: 100%;
            margin: 0.28em 0;
                li{
                    display: flex;
                    align-items: center;
                    //justify-content: center;
                    margin-left: 2em; 
                    height: 2em;
                    //background: #0D0127;
                    border-radius: 1em;
                    color: white;
                    a{
                        text-decoration: none;
                        color: inherit;
                    }
                }
        }
        .navbar-status{
            display: flex;
            flex-direction: column-reverse;
            align-items:flex-end;
            justify-content:start;
            margin: 0.6em 0;
            height: 100%;
            gap: 9.2vh;
            .navbar-actions{
                display: flex;
                flex-direction: column;
                gap: 9.2vh;
                margin-right: 2em;
                align-items: center;
                li{
                    list-style: none;
                    color: white;
                    a{
                        text-decoration: none;
                        color: inherit;
                    }
                }
                button{
                    background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                    color: white;
                    font-size: 16px;
                    padding: 0.6em 1em;
                    border: none;
                    border-radius: 0.4em;
                    transition: box-shadow 0.6s;
                    &:hover{
                        box-shadow: 0 0 30px 2px #FE107A;
                        transition: box-shadow 0.6s;
                    }
                }
                img{
                    // width: 40px;
                    // height: 40px;
                    // border-radius: 50%;
                    display: none;
                }
            }
        }
    }
}

@media only screen and (min-width: 700px){
    .navbar-hidden{
        transform: translateY(-10em);
        transition: 0.2s ease-in-out;
    }
    .navbar-container{
        align-items: center;
        justify-content: center;
        height:auto;
        transition: 0.2s ease-in-out;
        nav{
            width: 74vw;
            .navbar-navigation{
                display: flex;
                flex-direction: row;
                list-style: none;
                align-items: center;
                //gap: 2em;
                width: 48vw;
                max-width:32em;
                justify-content: space-between;
                img{
                    margin-right: 1.2em;
                    width: 104px;
                }
                li{
                    display: block;
                    color: white;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: inherit;
                        transition: 0.5s;
                        &:hover{
                            color: #FE107A;
                            transition: 0.5s;
                        }
                    }
                }
            }
            .navbar-status{
                .menu-btn{
                    display: none;
                }
                .navbar-actions{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 2em;
                    list-style: none;
                    color: white;
                    white-space: nowrap;
                    &:hover{
                        cursor: pointer;
                    }
                    li{
                        color: white;
                        cursor: pointer;
                        a{
                            text-decoration: none;
                            color: inherit;
                            &:hover{
                                color: #FE107A;
                                transition: 0.5s;
                            }
                        }
                    }
                    button{
                        background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
                        color: white;
                        font-size: 16px;
                        padding: 0.6em 1em;
                        border: none;
                        border-radius: 0.4em;
                        transition: box-shadow 0.6s;

                        display: flex;
                        flex-direction: row;
                        gap: 0.5em;

                        &:hover{
                            box-shadow: 0 0 30px 2px #FE107A;
                            transition: box-shadow 0.6s;
                        }
                    }
                    img{
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;
                        border: 1px solid #FB9758;
                    }
                }
            }
        }
    }
}