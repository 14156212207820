.promotional-container {
  width: 100vw;
  height: 100%;
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.promotional-container .desktop {
  display: none;
}
.promotional-container .promotional-content {
  display: flex;
  flex-direction: column;
  width: 80vw;
  color: white;
}
.promotional-container .promotional-content .promotional-subheading {
  font-weight: bold;
}
.promotional-container .promotional-content h1 {
  margin: 0;
}
.promotional-container .promotional-content P {
  line-height: 1.6em;
}
.promotional-container .promotional-content button {
  background: #0D1435;
  border: none;
  color: white;
  height: 3.5em;
  width: 15em;
  border-radius: 1em;
  font-size: 15px;
}
.promotional-container img {
  transform: translate(-10%, 0);
}

@media only screen and (min-width: 768px) {
  .promotional-container {
    display: flex;
    flex-direction: row;
    max-height: 40vh;
    overflow: hidden;
  }
  .promotional-container .desktop {
    display: block;
  }
  .promotional-container .mobile {
    display: none;
  }
  .promotional-container .promotional-content {
    margin-left: 20vw;
    max-width: 30vw;
    gap: 1em;
  }
  .promotional-container .promotional-content h1 {
    font-size: 40px;
  }
  .promotional-container .promotional-content p {
    max-width: 30em;
  }
  .promotional-container img {
    width: 45vw;
    transform: translate(5%, 10%);
  }
}/*# sourceMappingURL=Promotional.css.map */