.blogpage-container{
    color:white;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 6.4em 0 0 0;
    gap: 2.4em;
    .blogpage-header{
        width: 60vw;
        h1{
            margin: 0;
        }
        .blogpage-header-author{
            display: flex;
            flex-direction: row;
            gap: 1em;
            align-items: center;
            img{
                aspect-ratio: 1 / 1;
                object-fit: cover;
                width: 3.2em;
                height: 3.2em;
                border-radius: 100%;
            }
        }
    }
    .blogpage-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60vw;
        P{
            line-height: 1.8em;
            font-size: large;
            color: rgb(224, 224, 224);
        }
    }
}

@media only screen and (max-width: 1150px){
    .blogpage-container{
        .blogpage-header, .blogpage-content{
            width: 90vw;
        }
        .blogpage-content{
            P{
                line-height: 1.4em;
            }
        }
    }
}