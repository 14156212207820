.landing2-containter {
  margin: 0;
  padding: 4em 0 0 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  gap: 2em;
}
.landing2-containter .landing2-header {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: center;
}
.landing2-containter .landing2-header h1 {
  margin: 1.4em 0 1em 0;
  padding: 0;
  color: white;
  text-align: center;
}
.landing2-containter .landing2-header h1 span {
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.landing2-containter .landing2-header p {
  color: rgb(197, 197, 197);
  line-height: 1.8em;
  text-align: center;
  padding: 0 2em;
}
.landing2-containter .landing2-header p span {
  font-weight: 600;
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.landing2-containter .landing2-cta {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.landing2-containter .landing2-cta input {
  width: 80vw;
  font-size: large;
  padding: 0.6em 0.4em;
  background: #0c0222;
  border: 1px solid #FE107A;
  border-radius: 0.4em;
  color: white;
  transition: box-shadow 0.6s;
}
.landing2-containter .landing2-cta input:hover {
  cursor: pointer;
  box-shadow: 0 0 20px 2px #FE107A;
  transition: box-shadow 0.6s;
}
.landing2-containter .landing2-cta input::-moz-placeholder {
  color: rgb(197, 197, 197);
}
.landing2-containter .landing2-cta input::placeholder {
  color: rgb(197, 197, 197);
}
.landing2-containter .landing2-cta div {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.landing2-containter .landing2-cta button {
  width: 84vw;
  font-size: large;
  max-height: 2.5em;
  background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);
  border: none;
  border-radius: 0.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.landing2-containter .landing2-cta button:hover {
  cursor: pointer;
  box-shadow: 0 0 20px 2px #FE107A;
  transition: box-shadow 0.6s;
}
.landing2-containter .landing2-cta .scan-complete {
  background: #FB9758 !important;
}
.landing2-containter .landing2-cta select {
  width: 84vw;
  font-size: large;
  padding: 0.6em 0;
  background: #0c0222;
  border: 1px solid #FE107A;
  border-radius: 0.4em;
  color: white;
  transition: box-shadow 0.6s;
}
.landing2-containter .landing2-footer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}
.landing2-containter .landing2-footer p {
  color: rgb(197, 197, 197);
  font-size: 16px;
  text-align: center;
  padding: 0 2em;
  line-height: 1.8em;
}
.landing2-containter .landing2-footer ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90vw;
  overflow: hidden;
  border-radius: 1em;
  padding: 0.6em 0;
  margin: 0;
}
.landing2-containter .landing2-footer ul li {
  list-style: none;
  width: 10em;
  display: none;
}
.landing2-containter .landing2-footer ul li:nth-child(-n+3) {
  display: flex;
}
.landing2-containter .landing2-footer ul li img {
  width: 20vw;
  min-height: 20px;
  max-height: 30px;
}

@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }
  .landing2-containter {
    align-items: center;
  }
  .landing2-containter .landing2-header {
    width: 50em;
    max-width: 80vw;
  }
  .landing2-containter .landing2-header h1 {
    margin: 2em 0 0.4em 0;
    font-size: 60px;
  }
  .landing2-containter .landing2-header p {
    font-size: 20px;
  }
  .landing2-containter .landing2-cta {
    display: flex;
    flex-direction: column;
    width: 40em;
    max-width: 60vw;
  }
  .landing2-containter .landing2-cta input {
    width: 100%;
  }
  .landing2-containter .landing2-cta div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .landing2-containter .landing2-footer P {
    font-size: 20px;
  }
  .landing2-containter .landing2-footer ul {
    width: 65em;
    max-width: 80vw;
    align-items: center;
    padding: 0.8em 0;
    margin: 0 0 4em 0;
  }
  .landing2-containter .landing2-footer ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .landing2-containter .landing2-footer ul li img {
    width: 20vw;
    min-height: 20px;
    max-height: 35px;
  }
}
@keyframes rotate {
  from {
    rotate: 0deg;
  }
  50% {
    scale: 1 1.5;
  }
  to {
    rotate: 360deg;
  }
}/*# sourceMappingURL=landing.css.map */