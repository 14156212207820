.plans-container{
    width: 100vw;
    height: 100%;
    background: #0D0127;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2em 0;

    h1{
        font-size: 28px;
        margin-bottom: 0;
        // margin-top: 2em;
    }

    .plans-subheading{
        color: #BEB3D8;
        line-height: 1.5em;
        max-width: 80vw;
    }


    .plans-cards{
        display: flex;
        flex-direction: column;
        gap: 2em;
        text-align: start;

        .plan-63615ab136d8763ae46acd5b{
            background: linear-gradient(90deg, #FE107A 0%, #FB9758 100%);

            p{
                color: white !important;
            }

            li{
                color: white !important;
            }

            h1{
                h2{
                    display: none;
                }
            }

            span{
                background: white !important;
            }
        }

        .plans-plan{
            display: flex;
            flex-direction: column;
            border: 1px solid #3F2F66;
            border-radius: 1em;
            padding: 1em;
            gap: 1em;

            .plans-plan-header{
                display: flex;
                flex-direction: column;
                gap: 1em;
                h2{
                    font-weight: 400;
                    margin-bottom: 0;
                }
                h1{
                    display: flex;
                    flex-direction: row;
                    gap: 0.2em;
                    font-size: 36px;
                    margin: 0;
                    align-items: end;
                    h2{
                        font-size: 20px;
                        color: #BEB3D8;
                        margin: 0.2em 0;
                    }
                }
                p{
                    margin-top: 0;
                }
            }

            p{
                color: #BEB3D8;
            }

            span{
                width: 100%;
                height: 1px;
                background: #3F2F66;
            }

            button{
                width: 60%;
                height: 3.4em;
                font-size: 16px;
                margin: 1em 0;
                color: white;
                // padding: 0.5em 0;
                background: #2C2554;
                border: none;
                border-radius: 2em;

                &:hover{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            ul{
                list-style-type: none;
                // margin: 0;
                padding: 0 0.2em;
                display: flex;
                flex-direction: column;
                gap: 1.4em;

                li{
                    display: flex;
                    flex-direction: row;
                    gap: 1em;
                    color: #BEB3D8;

                    .checkmark-icon{
                        // color: white;
                        color: #FB9758;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px){
    .plans-container{
        // height: 100vh;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        width: 100vw;
        padding: 0;
        margin: 6em 0;
        gap: 1em;
        h1{
            font-size:42px;
        }
        .plans-subheading{
            display: block;
            text-align: center;
            font-weight: 400;
            color: #BEB3D8;
            font-size: 16px;
            max-width: 45em;
        }
        .plans-cards{
            width: 100%;
            height: 100%;
            flex-direction: row;
            justify-content: center;
            // gap: 1.4em;
            
            .plan-63615ab136d8763ae46acd5b{
                // transform: translateY(-5%);
                margin-bottom: 2em;
            }

            .plans-plan{
                // padding: 0 1em;
                .plans-plan-header{
                    h1{
                        font-size: 40px;
                        margin: 0;
                    }
                }

                button{
                    margin-bottom: 0;
                }
            }

            div{
                //height: 100%;
                width: auto;
                // max-width: 20vw;
                // min-width: 16em;
                max-width: 20vw;
                width: 22em;
                div{
                    // padding: 0 4em;
                }
                h1{
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}